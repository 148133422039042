<template lang='pug'>
	.landing.landing-clubhouse.overflow-hidden
		intro
		whatisit
		howtodoit
		profit
		andmore
		room
		.content
			.wrapper-content
				.b-card-clubhouse.b-card-clubhouse-help
					div
						.media
							.cirle-outline-gray.d-flex.justify-content-center.align-content-center
								//-img(src='/assets/img/hand.svg')
							div
								h2.landing-h2 Нужна помощь?
								.landing-desc.landing-desc-sm Пишите в поддержку, персональный менеджер
									br
									|  поможет вам настроить бота.
					a(:href='botSupport' target='_blank')
						b-btn.landing-btn.landing-clubhouse-btn.landing-clubhouse-btn-gray.text-nowrap( size='xl') 🎉 &nbsp;@{{$t('contacts.support_tg_bot')}}
</template>
<style src="@/assets/fonts/gtrestiprodisplay/stylesheet.css"></style>
<style lang='scss'>
	@import '~@/assets/css/sass/_mixins.scss';

	:root {
		--lColor0: #29AD60;
		--lColor1: #664E27;
		--lColor2: #FFAE52;
		--lColor3: #F2EEE4;
		--lColor4: #A6B2BF;
		--lColor5: #F8CD46;
		--lColor6: #E7E2D5;
		--lColor7: #19934C;
		--lColor8: #373530;
		--lColor9: #696861;
	}

	.landing {
		.wrapper-content {
			max-width: 1370px;
			margin: 0 auto;
		}
	}

	.landing-clubhouse {
		background: var(--lColor3);
		a:hover {
			color: var(--lColor0);
		}
	}

	.landing-h1 {
		color: var(--lColor8);
		font-weight: 500;
	}

	.landing-h2 {
		color: var(--lColor8);
		font-weight: normal;
		font-size: 40px;
	}

	.landing-desc {
		color: var(--lColor9);
		font-size: 24px;
	}

	.landing-desc-sm {
		font-size: 18px;
	}

	.btn-white {
		background: #fff;
		color: var(--lColor1);
		box-shadow: 0px 10px 22px -8px rgba(102, 78, 39, 0.13);
		padding: 16px 31px;

		&:hover {
		color: var(--lColor2);
		}
	}

	.b-card-clubhouse {
		background: #fff;
		border-radius: 53px;
		padding: 90px;
		width: 100%;
	}

	.b-card-clubhouse-gray {
		background: var(--lColor6);
	}

	.b-card-clubhouse-help {
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
	}

	.landing-clubhouse-btn {
		height: 84px;
		width: auto;
		padding: 28px 45px;
	}

	.landing-clubhouse-btn-gray {
		background: var(--lColor3);
		border: 2px solid var(--lColor3);
		color: var(--lColor8);

		&:hover {
			background: #fff;
			border-color: var(--lColor3);
			color: var(--lColor8);
		}
	}

	.cirle-outline-gray {
		border: 3px solid var(--lColor3);
		border-radius: 80px;
		width: 154px;
		height: 154px;
	}

	.b-card-clubhouse-help {
		.cirle-outline-gray {
			margin-right: 80px;

			img {
				width: 57px;
    			margin-left: 10px;
			}
		}
	}

	.land-footer {
		padding: 50px 0;
	}

	.man {
		//background: url('/assets/img/man.png') no-repeat;
		background-size: contain;
		border-radius: 20%;
		width: 202px;
		height: 202px;
	}

	.need-help-desc {
		font-size: 18px;
		font-weight: 300;
		line-height: 140%;
	}

	@media (min-width: 1200.01px) and (max-width: 1400px) {
		.landing {
			.wrapper-content {
				max-width: 1140px;
			}
		}

		.landing-h1 {
			font-size: 54px;
		}

		.landing-h2 {
			font-size: 36px;
		}

		.landing-desc {
			font-size: 22px;
		}

		.landing-desc-sm {
			font-size: 16px;
		}

		.need-help-desc {
			font-size: 16px;
		}

		.b-card-clubhouse-help {
			padding: 80px;

			.cirle-outline-gray {
				width: 130px;
				height: 130px;

				margin-right: 55px;

				img {
					width: 47px;
				}
			}
		}
	}

	@media (min-width: 1024.01px) and (max-width: 1200px) {
		.landing-btn {
			font-size: 20px;
			line-height: 23px;
		}
	}

	@media (min-width: 768.01px) and (max-width: 1200px) {
		.landing {
			.wrapper-content {
				max-width: 960px;
			}
		}

		.landing-h1 {
			font-size: 43px;
		}

		.landing-h2 {
			font-size: 28px;
		}

		.landing-desc {
			font-size: 18px;
		}

		.landing-desc-sm {
			font-size: 14px;
		}

		.need-help-desc {
			font-size: 14px;
		}

		.b-card-clubhouse-help {
			padding: 60px 60px 70px;

			.cirle-outline-gray {
				width: 120px;
				height: 120px;

				margin-right: 50px;

				img {
					width: 44px;
				}
			}
		}
	}

	@media (min-width: 768.01px) and (max-width: 900px) {
		.b-card-clubhouse-help {
			padding: 40px;
		}
	}

	@media (max-width: 767.98px) {
		.landing {
			.wrapper-content {
				max-width: 100%;
				padding: 0 15px;
			}
		}

		.landing-h1 {
			font-size: 30px;
		}

		.landing-h2 {
			font-size: 22px;
		}

		.landing-desc {
			font-size: 14px;
		}

		.landing-desc-sm {
			font-size: 12px;
		}

		.need-help-desc {
			font-size: 14px;
		}

		.b-card-clubhouse-help {
			padding: 50px 38px 38px;
			flex-direction: column;
			align-items: center;
			text-align: center;

			.media {
				flex-direction: column;
				align-items: center;
			}

			.cirle-outline-gray {
				width: 90px;
				height: 90px;

				margin-right: 0;
				margin-bottom: 25px;

				img {
					width: 42px;
				}
			}

			.landing-desc-sm {
				margin-bottom :25px;
			}
		}
	}
</style>

<script>
import Intro from '@/containers/clubhouse/Intro'
import WhatIsIt from '@/containers/clubhouse/WhatIsIt'
import HowToDoIt from '@/containers/clubhouse/HowToDoIt'
import AndMore from '@/containers/clubhouse/AndMore'
import Profit from '@/containers/clubhouse/Profit'
import Room from '@/containers/clubhouse/Room'

import { mapActions } from 'vuex'


export default {
	metaInfo : {
		titleTemplate: 'Paywall - монетизация Clubhouse. Доход с помощью закрытых комнат.'
	},
	components: {
		'intro'					: Intro,
		'whatisit'              : WhatIsIt,
		'howtodoit' 			: HowToDoIt,
		'andmore' 				: AndMore,
		Profit,
		Room,
	},
	data() {
		return {

		}
	},
	beforeMount () {
		document.body.classList.add("body-landing");
		document.body.classList.add("body-clubhouse");
	},

	destroyed () {
		document.body.classList.remove("body-landing");
		document.body.classList.remove("body-clubhouse");
	},
	computed: {

	},
	mounted () {
		if (this.$route.hash) {
			setTimeout(() => this.scrollTo(this.$route.hash), 100)
		}

		this.rememberPageView();
	},

	methods : {
		...mapActions({

		}),

		scrollTo: function (hashtag) {
			setTimeout(() => { location.href = hashtag }, 100)
		}
	}
}
</script>
