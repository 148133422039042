<template lang='pug'>
	.profit.text-center#profit
		.content
			.wrapper-content
				h2.landing-h1 Польза закрытого канала/чата
					br
					|  для ваших подписчиков
					br
					|  в Clubhouse

				.profit--list
					.profit--list--item(v-for='(i, index) in profits' :key='i.img')
						.profit--list--item--icon-bg
							img.profit--list--item--icon(:src='i.icon')
						.profit--list--item--desc(v-html='i.desc')

				h2.landing-h1#examples Примеры монетизации
					br
					|  закрытых комнат в Clubhouse
				.landing-desc Пользователь может подписаться на неделю, месяц или другой
					br.d-none.d-sm-block
					|  установленный период или тариф. Доступна также единоразовая оплата.
					br
					br.d-sm-none
					| &nbsp;
					a.text-underline(href='https://youtu.be/LwXe_suBuvo' target='_blank') Подробнее смотрите в скринкасте 📺

				.card--list
					.card--list--item.b-card-clubhouse(v-for='(i, index) in examples' :key='i.img')
						.card--list--item--icon-bg
							img.card--list--item--icon(:src='i.icon')
						.card--list--item--desc(v-html='i.desc')

				a(:href='botSupport' target='_blank')
					b-btn.landing-btn.position-relative(variant='primary' size='xl')
						span.btn-icon 🎉
						|  &nbsp;Обсудить мой проект с менеджером
</template>

<style lang="scss" scoped>
	@import '~@/assets/css/sass/_mixins.scss';

	.profit {
		padding: 150px 0;
	}

	.landing-btn {
		width: auto;
    	padding: 0 40px;
	}

	.profit--list {
		margin: 80px auto 135px auto;
		max-width: 1100px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.profit--list--item {
		display: inline-block;
		width: calc(33% - 90px);
		margin: 0 65px 90px;
		vertical-align: top;

		&:nth-child(1) {
			margin-left: 0;

			.profit--list--item--icon {
				width: 170px;
				height: 182px;
			}
		}

		&:nth-child(2) {
			.profit--list--item--icon {
				width: 167px;
				height: 185px;
			}
		}

		&:nth-child(3) {
			margin-right: 0;

			.profit--list--item--icon {
				width: 168px;
				height: 161px;
			}
		}

		&:nth-child(4) {
			margin-left: 0;

			.profit--list--item--icon {
				width: 183px;
				height: 168px;
			}
		}

		&:nth-child(5) {
			margin-right: 0;
			.profit--list--item--icon {
				width: 183px;
				height: 166px;
			}
		}
	}

	.profit--list--item--icon-bg {
		background: #fff;
		border-radius: 40%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 265px;
		height: 265px;
		margin-bottom: 30px;
	}

	.profit--list--item--desc {
		color: #000;
		font-size: 20px;
		line-height: 130%;
	}

	.card--list {
		margin: 100px 0;
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 40px;
	}

	.card--list--item {
		display: inline-block;
		padding: 72px 45px 67px;
		text-align: center;

		&:nth-child(1) {
			.card--list--item--icon {
				margin-left: 10px;
			}
		}

		&:nth-child(2) {
			.card--list--item--icon {
				margin: -5px 0 0 10px;
			}
		}

		&:nth-child(3) {
			.card--list--item--icon {
				margin-right: 10px;
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}

	.card--list--item--icon-bg {
		background: var(--lColor3);
		border-radius: 40%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 140px;
		height: 140px;
		margin-bottom: 40px;
	}

	.card--list--item--desc {
		color: #000;
		font-size: 18px;
		line-height: 130%;
	}

	@media (min-width: 1200.01px) and (max-width: 1400px) {
		.profit {
			padding: 100px 0;
		}

		.profit--list {
			margin-bottom: 70px;
		}

		.profit--list--item--icon-bg {
			zoom: .9;
		}

		.profit--list--item {
			margin: 0 45px 80px;
		}

		.profit--list--item--desc {
			font-size: 18px;
		}

		.landing-h1 {
			margin-bottom: 50px;
		}

		.card--list {
			margin: 80px 0;

			grid-gap: 30px;
		}

		.card--list--item {
			padding: 60px 22px 34px;
		}

		.card--list--item--icon-bg {
			width: 116px;
			height: 116px;
			margin-bottom: 35px;
		}

		.card--list--item--desc {
			font-size: 16px;
		}
	}

	@media (min-width: 768.01px) and (max-width: 1200px) {
		.profit {
			padding: 90px 0;
		}

		.profit--list {
			margin-bottom: 20px;
		}

		.profit--list--item--icon-bg {
			zoom: .8;
		}

		.profit--list--item {
			width: calc(33% - 50px);
			margin: 0 30px 60px;
		}

		.profit--list--item--desc {
			font-size: 18px;
		}

		.landing-h1 {
			margin-bottom: 25px;
		}

		.card--list--item {
			padding: 50px 18px 30px;
		}

		.card--list--item--icon-bg {
			width: 100px;
			height: 100px;
			margin-bottom: 30px;
		}

		.card--list--item--desc {
			font-size: 14px;
		}
	}

	@media (min-width: 1024.01px) and (max-width: 1200px) {
		.card--list {
			margin: 70px 0;

			grid-gap: 25px;
		}
	}

	@media (min-width: 768.01px) and (max-width: 1024px) {
		.card--list {
			margin: 70px auto;

			grid-gap: 20px;
		}
	}

	@media (max-width: 767.98px) {
		.profit {
			padding: 50px 0;
		}

		.profit--list {
			margin-top: 40px;
			margin-bottom: 40px;
		}

		.profit--list--item--icon-bg {
			zoom: .52;
		}

		.profit--list--item {
			width: calc(50% - 50px);
			margin: 0 20px 40px;

			&:nth-child(1) {
				margin-left: 0;
			}

			&:nth-child(2) {
				margin-right: 0;
			}

			&:nth-child(3) {
				margin-right: 20px;
				margin-left: 0;
			}

			&:nth-child(4) {
				margin-right: 0;
				margin-left: 20px;
			}

			&:nth-child(5) {
				margin-right: 0;
				margin-left: 0;
			}
		}

		.profit--list--item--desc {
			font-size: 13px;
		}

		.landing-h1 {
			margin-bottom: 20px;
		}

		.card--list {
			margin: 50px 0 40px;
			grid-template-rows: repeat(2, auto);
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 20px;
		}

		.card--list--item {
			padding: 35px 10px 26px;
			border-radius: 15px;
		}

		.card--list--item--icon {
			zoom: .6;
		}

		.card--list--item--icon-bg {
			width: 72px;
			height: 72px;
			margin-bottom: 20px;
		}

		.card--list--item--desc {
			font-size: 12px;
		}

		.landing-btn {
			text-indent: 20px;
		}
		.btn-icon {
			position: absolute;
			top: 50%;
			left: 10px;
			transform: translateY(-50%);
		}
	}
</style>

<script>


	export default {
		name 	: 'Profit',
		components : {

		},
		mounted () {

		},
		data: () => ({
			profits : [
				/*{icon: '/assets/img/profit1.png', desc: 'Комьюнити только<br> заинтересованных людей'},
				{icon: '/assets/img/profit2.png', desc: 'Сопроводительные материалы к выступлению'},
				{icon: '/assets/img/profit3.png', desc: 'Возможность нетворкинга и взаимной подписки'},
				{icon: '/assets/img/profit4.png', desc: 'Планирование мероприятия и обсуждение в чате'},
				{icon: '/assets/img/profit5.png', desc: 'Уведомления в удобном для вашей аудитории мессенджере'}*/
			],
			examples : [
				/*{icon: '/assets/img/example1.svg', desc: 'Еженедельный обзор рынка, дискуссии с лидерами отрасли'},
				{icon: '/assets/img/example2.svg', desc: 'Тренинги, семинары,<br> мастер-классы'},
				{icon: '/assets/img/example3.svg', desc: 'Конференции, аудио-спектакли, лекции'},
				{icon: '/assets/img/example4.svg', desc: 'Питчи, чтения, медитации, стенд-апы'},*/
			]
		}),
		computed: {

		},

		beforeDestroy() {

		},

		methods : {

		}
	}
</script>
